.Header {
    height: 80px;
    background-color: #152c73;
    color: white;
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 10%;
    font-size: 24px;
}

.responsive {
    width: auto;
    height: 20px;
}
