.DocumentPreview {
    margin: 0 auto;
    margin-top: 15px;
    width: 85%;
}

.DocumentPreview p {
    color: #bbb;
}

.DocumentPreview object {
    max-width: 100%;
}

@media only screen and (max-width: 720px) {
    .DocumentPreview {
        width: 90%;
    }
}