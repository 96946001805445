.App {
    text-align: center;
    background-color: #eee;
}

section.Menu {
    margin-left: 15%;
    margin-right: 15%;
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: white;
}

@media only screen and (max-width: 720px) {
    section.Menu {
        margin-left: 0;
        margin-right: 0;
    }
}
