body {
    margin: 0;
    background-color: #eee;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.row {
    width: 100%;
}

.fas {
    font-family: "FontAwesome" !important;
}

pre {
    text-align: left;
    white-space: pre;
    font-family: monospace;
    border: 1px solid #aaa;
    background-color: #ddd;
    border-radius: 5px;
}
